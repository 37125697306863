import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import Img from 'gatsby-image';
import Social from '../Social';
import * as T from '../Typography'
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  profiles?: Array<{
    image: any;
    name: string;
    title: string;
    linkedInUrl?: string;
    twitterUrl?: string;
    facebookUrl?: string;
    instagramUrl?: string;
  }>;
}

const Profile = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Profile>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <S.Members>
            <Grid columns={12}>
              {props.profiles &&
                props.profiles.map((profile, index) => (
                  <Cell
                    key={index}
                    width={isMobile ? 7 : 3}
                    left={isMobile ? 1 : index % 2 ? 8 : 3}
                  >
                    <S.Member index={index}>
                      <Img
                        backgroundColor="var(--grey)"
                        className="image"
                        fluid={profile.image.childImageSharp.fluid}
                      />
                      <S.Name as={isMobile ? T.H5 : T.H4}>{profile.name}</S.Name>
                      <S.Title>{profile.title}</S.Title>
                      <S.Social>
                        <Social
                          color={'var(--black)'}
                          size="40px"
                          linkedInUrl={profile.linkedInUrl}
                          twitterUrl={profile.twitterUrl}
                          facebookUrl={profile.facebookUrl}
                          instagramUrl={profile.instagramUrl}
                        />
                      </S.Social>
                    </S.Member>
                  </Cell>
                ))}
            </Grid>
          </S.Members>
        </Cell>
      </Grid>
    </S.Profile>
  );
};

export default withBreakpoints(Profile);
