import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Profile from '../components/Profile';
import BoxImage from '../components/BoxImage';
import Background from '../components/Background';
import Head from '../components/Head';

const TeamPage = ({ data }) => {
  const team = data.team.frontmatter;

  return (
    <Layout>
      <Background />
      <Head title={team.title} description={team.description} />
      <Header {...team.header} />
      <Profile {...team.profile} />
      <BoxImage {...team.boxImage2} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query SiteTeamPageQuery {
    team: markdownRemark(frontmatter: { templateKey: { eq: "team" } }) {
      frontmatter {
        title
        description
        header {
          headline
          straplines
          address
        }
        profile {
          profiles {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            title
            linkedInUrl
            twitterUrl
            facebookUrl
            instagramUrl
          }
        }
        boxImage2 {
          boxes2 {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            headline
            button {
              label
              url
            }
          }
        }
      }
    }
  }
`;

export default TeamPage;
