import styled from 'styled-components';
import { Section }  from '../Layout/styles';
import * as T from '../Typography';

interface IProps {
  index: number;
}

export const Profile = styled(Section)`
  background-color: var(--yellow);
  /* stylelint-disable */
  *::selection {
    background-color: var(--grey);
  }
  /* stylelint-enable */
`;

export const Members = styled.div``;

export const Member = styled.div`
  margin-top: var(--x4-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: ${(props: IProps) =>
      (props.index % 2) ? `var(--x6-space);` : `0;`}
  }
`;

export const Name = styled.div`
  margin-top: var(--x1-space);
  color: var(--black);
  white-space: nowrap;
`;

export const Title = styled(T.Label)`
  color: var(--black);
  margin-top: var(--xhalf-space);
  margin-left: .125rem;
`;

export const Social = styled.div`
  color: var(--black);
`;
